import { defineComponent, inject, ref, computed } from 'vue';
import MemberCenterLayout from '@/components/personal/MemberCenterLayout.vue';
import Level from '@/components/personal/Level.vue';
import LevelInfo from '@/components/personal/LevelInfo.vue';
import { useAuthStore } from '@/hook/useAuthStore';
import { useStore } from 'vuex';
import { LEVEL_MAPPING } from '@/utils/levelMapping';
import { numberWithCommas } from '@/utils/numberWithCommas';
import endOfMonth from 'date-fns/esm/fp/endOfMonth/index.js';
import { format } from 'date-fns';
export default defineComponent({
  name: 'MemberLevel',
  components: {
    MemberCenterLayout: MemberCenterLayout,
    Level: Level,
    LevelInfo: LevelInfo
  },
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var isOpenPointPopup = ref(false);

    var handlePointPopup = function handlePointPopup(isOpen) {
      isOpenPointPopup.value = isOpen;
    };

    var store = useStore();
    var userInfo = useAuthStore().userInfo;
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    var levelId = computed(function () {
      return levelInfo.value.level_id || 1;
    });

    var getCardImg = function getCardImg(levelId) {
      return require("@/assets/images/member-card-vip".concat(levelId, ".png"));
    };

    var levelExpired = computed(function () {
      return levelInfo.value.downgrade_alert ? format(endOfMonth(new Date()).getTime(), 'yyyy年MM月dd日') : '';
    });
    return {
      t: t,
      isMobile: isMobile,
      isOpenPointPopup: isOpenPointPopup,
      handlePointPopup: handlePointPopup,
      userInfo: userInfo,
      levelInfo: levelInfo,
      levelId: levelId,
      getCardImg: getCardImg,
      numberWithCommas: numberWithCommas,
      LEVEL_MAPPING: LEVEL_MAPPING,
      levelExpired: levelExpired
    };
  }
});