import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-50b743cc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = {
  class: "header-card"
};
var _hoisted_3 = {
  class: "card-frame"
};
var _hoisted_4 = {
  class: "info"
};
var _hoisted_5 = {
  class: "list"
};
var _hoisted_6 = {
  key: 0,
  class: "item"
};
var _hoisted_7 = {
  key: 1,
  class: "item"
};
var _hoisted_8 = {
  key: 2,
  class: "item"
};
var _hoisted_9 = {
  key: 3,
  class: "item"
};
var _hoisted_10 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_level = _resolveComponent("level");

  var _component_level_info = _resolveComponent("level-info");

  var _component_member_center_layout = _resolveComponent("member-center-layout");

  return _openBlock(), _createBlock(_component_member_center_layout, null, {
    default: _withCtx(function () {
      return [_createElementVNode("main", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_level, {
        customStyle: "bigTitle"
      }), _createElementVNode("div", _hoisted_5, [_ctx.levelInfo.downgrade_alert ? (_openBlock(), _createElementBlock("div", _hoisted_6, "會籍到期日：" + _toDisplayString(_ctx.levelExpired), 1)) : _createCommentVNode("", true), _ctx.levelId === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_7, "會籍條件：永久有效")) : (_openBlock(), _createElementBlock("div", _hoisted_8, "會籍條件：12 個月內累積消費額達 TWD " + _toDisplayString(_ctx.numberWithCommas(_ctx.LEVEL_MAPPING[_ctx.levelId - 1].price)), 1)), _ctx.levelId < 5 ? (_openBlock(), _createElementBlock("div", _hoisted_9, "升級至" + _toDisplayString(_ctx.LEVEL_MAPPING[_ctx.levelId].name) + "：12 個月內累積消費額達 TWD " + _toDisplayString(_ctx.numberWithCommas(_ctx.LEVEL_MAPPING[_ctx.levelId].price)), 1)) : _createCommentVNode("", true)])]), _createElementVNode("img", {
        class: "level-card",
        src: _ctx.getCardImg(_ctx.levelId)
      }, null, 8, _hoisted_10)])]), _createVNode(_component_level_info)])];
    }),
    _: 1
  });
}