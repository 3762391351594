import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from '@/hook/useAuthStore';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import LevelStep from '@/components/personal/LevelStep.vue';
import router from '@/router';
export default defineComponent({
  name: 'LevelInfo',
  setup: function setup() {
    var store = useStore();
    var userInfo = useAuthStore().userInfo;
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    var levelId = computed(function () {
      return store.state.user.levelInfo.level_id - 1;
    });
    var list = ref(true);
    var isOpenPopup = ref(false);
    var isActiveTab = ref('1');

    var handleActiveTab = function handleActiveTab(tabIndex) {
      isActiveTab.value = tabIndex;
    };

    var tabsData = {
      1: {
        title: '普卡',
        content: [{
          icon: 'icon-60-bed',
          subTitle: '訂房優惠',
          dest: '官網會員專屬，訂房享優惠'
        }, {
          icon: 'icon-60-gift',
          subTitle: '新會員註冊禮',
          dest: '積分大放送，立即享有超值 100 點！'
        }, {
          icon: 'icon-60-point',
          subTitle: '好友推薦禮',
          dest: '邀請好友註冊會員，100 點輕鬆領取！'
        }]
      },
      2: {
        title: '銀卡',
        content: [{
          icon: 'icon-60-bed',
          subTitle: '訂房優惠',
          dest: '官網會員專屬，訂房享優惠'
        }, {
          icon: 'icon-60-discount',
          subTitle: '點數回饋',
          dest: '最高賺取點數 5% 回饋'
        }, {
          icon: 'icon-60-point',
          subTitle: '好友推薦禮',
          dest: '邀請好友註冊會員，100 點輕鬆領取！'
        }]
      },
      3: {
        title: '金卡',
        content: [{
          icon: 'icon-60-bed',
          subTitle: '訂房優惠',
          dest: '官網會員專屬，訂房享優惠'
        }, {
          icon: 'icon-60-discount',
          subTitle: '點數回饋',
          dest: '最高賺取點數 8% 回饋'
        }, {
          icon: 'icon-60-point',
          subTitle: '好友推薦禮',
          dest: '邀請好友註冊會員，100 點輕鬆領取！'
        }]
      },
      4: {
        title: '白金',
        content: [{
          icon: 'icon-60-bed',
          subTitle: '訂房優惠',
          dest: '官網會員專屬，訂房享優惠'
        }, {
          icon: 'icon-60-discount',
          subTitle: '點數回饋',
          dest: '最高賺取點數 10% 回饋'
        }, {
          icon: 'icon-60-point',
          subTitle: '好友推薦禮',
          dest: '邀請好友註冊會員，100 點輕鬆領取！'
        }]
      },
      5: {
        title: '鑽石',
        content: [{
          icon: 'icon-60-bed',
          subTitle: '訂房優惠',
          dest: '官網會員專屬，訂房享優惠'
        }, {
          icon: 'icon-60-discount',
          subTitle: '點數回饋',
          dest: '最高賺取點數 15% 回饋'
        }, {
          icon: 'icon-60-point',
          subTitle: '好友推薦禮',
          dest: '邀請好友註冊會員，100 點輕鬆領取！'
        }]
      }
    };

    var goToMemberShip = function goToMemberShip() {
      router.push({
        name: 'Membership'
      });
    };

    return {
      levelInfo: levelInfo,
      levelId: levelId,
      userInfo: userInfo,
      list: list,
      isOpenPopup: isOpenPopup,
      goToMemberShip: goToMemberShip,
      handleActiveTab: handleActiveTab,
      tabsData: tabsData,
      isActiveTab: isActiveTab
    };
  },
  components: {
    SvgIcon: SvgIcon,
    LevelStep: LevelStep
  }
});